body {
  background-color: white;
  font-family: 'Roboto', sans-serif;
  color: black;
}

.page-container {
  display: grid;
  grid-template-areas:
    'nav'
    'content';
  grid-template-rows: 54px 1fr;
  grid-template-columns: 1fr;
  height: 100vh;
  width: 100vw;
}

.page-content-container {
  grid-area: content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
