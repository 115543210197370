/* NAVBAR */
a {
  text-decoration: none;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-area: nav;
}

.logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo-icon {
  width: 65px;
}

.logo-text {
  color: black;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  padding-left: 10px;
}

.nav-button-container {
  width: 250px;
  display: flex;
  flex-direction: row;
  align-self: 'right';
  justify-content: space-evenly;
}

.desktop-nav-button {
  color: black;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
}

.desktop-nav-button:hover {
  color: #b3b3b3;
}

.app-bar {
  color: black;
  padding-right: 35px;
  cursor: pointer;
}

.app-bar:hover {
  color: #b3b3b3;
}

.app-bar-menu {
  padding-top: 60px;
  position: fixed;
  background: white;
  border: black;
  border-width: 2px;
  z-index: 5;
  top: 0;
  right: 0;
  width: 40vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  transition: 0.5s;
  text-align: center;
  /* transition:ease; */
}

.blur-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #3e3d3d;
  opacity: 0.85;
  overflow: hidden; /* disable scrolling*/
  z-index: 3; /* higher than all other items, but lower than 
    the appbar*/
}

.close-feedback-icon {
  position: fixed;
  top: 10px;
  right: 10px;
  color: black;
  cursor: pointer;
}

.app-bar-menu-item {
  margin: 15px;
  color: black;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
}

.app-bar-menu-item:hover {
  color: #b3b3b3;
}
